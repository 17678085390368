//通用的脚组件
<template>
  <div class="foot">
    <!-- 客服电话 -->
    <div class="call">
      <div class="common_bom_h">
        <img class="logo_img" src="../assets/img/logo.png" />
      </div>
      <div class="common-top common_bom_size">客服电话：19145076011</div>
      <div class="common-top common_bom_size    ">客服：周一至周日 9：00 - 17：30</div>
    </div>

    <!-- 间隔线 -->
    <div class="line"></div>

    <!-- 联系我们 -->
    <div class="contact_us">
      <div class="common-weight common_bom_h">联系我们</div>

      <!-- 邮箱 -->
      <div class="mail_box common-top common_bom_size"><span class="iconfont cloud-youxiang"></span>邮&nbsp;&nbsp;&nbsp;&nbsp;箱：3462446029@qq.com</div>

      <!-- 地址 -->
      <div class="address common-top common_bom_size">
        <span class="iconfont cloud-dizhi"></span>地&nbsp;&nbsp;&nbsp;&nbsp;址：武汉市江汉区西北湖广场国贸新都B座17楼B室
      </div>
    </div>

    <!-- 间隔线 -->
    <div class="line"></div>

    <!-- 二维码 -->
    <div class="code">
      <img style="cursor: pointer" preview src="../assets/img/code.jpg" width="80px" />
      <div class="common-top common_bom_size">官方微信</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
  computed: {},
  components: {}
};
</script>
<style lang="scss" scoped>
.foot {
  font-family: "Microsoft YaHei";
  margin-top: -4px;
  width: 100%;
  background: #0d0d0d;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;

  .common_bom_h {
    height: 50px;
    display: flex;
    align-items: center;
  }

  .common_bom_size {
    font-size: 14px;
  }
  .call {
    color: white;
    font-size: 14px;
    .logo_img {
      width: 88px;
    }
  }
  .contact_us {
    color: white;
    .mail_box {
      display: flex;
      align-items: center;
      .cloud-youxiang {
        margin-right: 6px;
      }
    }

    .address {
      display: flex;
      align-items: center;
      .cloud-dizhi {
        margin-right: 6px;
      }
    }
  }

  .code {
    flex-direction: column;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .line {
    width: 0px;
    height: 40%;
    background: white;
    margin: 0 110px;
  }

  .common-top {
    margin-top: 12px;
  }
  .common-weight {
    font-weight: bold;
    // font-size: 18px;
  }
}
</style>
