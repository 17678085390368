//未登录的头部组件
<template>
  <div class="un_login_head">
    <!--  导航条部分 -->
    <div class="content">
      <!-- logo部分 -->
      <div class="logo">
        <img height="58px" src="../assets/img/logo.png" />
      </div>

      <!-- 未登录切换的导航条部分 -->
      <div class="top_head">
        <div style="margin-right: 35px; cursor: pointer;" @click.stop="doEnterCompetition">赛事中心</div>
        <top-nav-un-login @doTopNavClick="doLoginClick"></top-nav-un-login>
      </div>

      <!-- 登录和登录后显示的头像以及角色类型部分 -->
      <div class="user_message">
        <div class="course_style" @click="doLoginClick">登&nbsp;&nbsp;录</div>
      </div>
    </div>
  </div>
</template>
<script>
//引入顶部导航栏
import TopNav from "@/components/TopNav.vue";
import TopNavUnLogin from "@/components/TopNavUnLogin.vue";
export default {
  data() {
    return {
      hasLogin: false,
      userType: "管理员"
    };
  },
  created() {},
  methods: {
    //点击登录的操作
    doLoginClick() {
      //触发父组件的弹出登录对话框的方法
      this.$emit("showLoginDialog");
    },

    //进入赛事中心的方法
    doEnterCompetition() {
      //跳转到赛事中心页面
      this.$router.push("/competitonCenter/LessonCenter");
    }
  },
  components: {
    TopNav,
    TopNavUnLogin
  }
};
</script>
<style lang="scss" scoped>
.un_login_head {
  height: 87px;
  width: 100%;
  background: $common_bg;
  .content {
    height: 100%;
    width: $common_width;
    margin: 0 auto;
    display: flex;
    .logo {
      flex: 1;
      display: flex;
      align-items: center;
    }

    .top_head {
      display: flex;
      color: white;
      font-weight: bold;
      font-size: 16px;
      align-items: center;
      justify-content: center;
    }

    //登录后信息
    .login_info {
      display: flex;
      align-items: center;
      color: white;
      font-size: 14px;
      .user_type {
        margin-left: 12px;
      }
    }

    // 用户登录信息以及登录后的头像信息
    .user_message {
      display: flex;
      align-items: center;
      min-width: 160px;
      color: white;
      font-size: 14px;
    }

    .course_style {
      cursor: pointer;
      font-weight: bold;
      font-size: 16px;
    }
  }
}
</style>
