// 未登录页面
<template>
  <div class="home">
    <!-- 头部 -->
    <un-login-head @showLoginDialog="showLoginDialog"></un-login-head>

    <!-- 顶部的展示部分 -->
    <div class="dv_top">
      <!-- common_height -->
      <img class="top_img" src="../assets/img/top_home_1.png" />
      <!-- 图片 -->
      <img @click.stop="showLoginDialog" class="img_log" src="../assets/img/bt_login.png" />
    </div>

    <!-- 图片一 -->
    <img class="common_img" src="../assets/img/home_banner1.png" />

    <!-- 图片二  -->
    <img class="common_img" src="../assets/img/home_banner2.png" />

    <!-- 图片三 -->
    <img class="common_img" src="../assets/img/home_banner3.png" />

    <!-- 图片四 -->
    <img class="common_img" src="../assets/img/home_banner4.png" />

    <!-- 脚部 -->
    <common-foot></common-foot>

    <!-- 登录页的弹框 -->
    <LoginPage @close="closeLoginDialog" :showDialog="showLoginPageDialog" @doLoginCommit="doLoginCommit" />
  </div>
</template>

<script>
//引入头部组件
import UnLoginHead from "@/components/UnLoginHead";

//引入脚部
import CommonFoot from "@/components/CommonFoot";

//登录页弹框
import LoginPage from "@/views/dialog/LoginPage";
export default {
  data() {
    return {
      showLoginPageDialog: false
    };
  },
  mounted() {
    if (!this.isEmpty(localStorage.getItem("userInfo"))) {
      this.$router.push("/LoginContain/CourseCenter");
    }
  },
  methods: {
    //弹出登录的对话框
    showLoginDialog() {
      this.showLoginPageDialog = true;
    },
    //关闭登录按钮
    closeLoginDialog() {
      this.showLoginPageDialog = false;
    },

    //登录提交
    doLoginCommit() {
      this.$router.push("/LoginContain/CourseCenter");
    }
  },
  components: {
    UnLoginHead,
    CommonFoot,
    LoginPage
  }
};
</script>

<style lang="scss" scope>
.home {
  height: 100vh;
  overflow-y: auto;
  .common_img,
  .top_img {
    width: 100%;
    object-fit: cover;
    margin-top: -4px;
  }

  .dv_top {
    position: relative;
    .img_log {
      cursor: pointer;
      width: 128px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 260px;
    }
  }
}
// .home::-webkit-scrollbar {
//   display: none; /* Chrome Safari */
// }
</style>
